import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import WhoAreWe from "../../components/who-are-we"
import SEO from "../../components/seo"
import helpers from "../../scripts/helpers"

class Bosch extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            
            <div className="hero hero--smallest hero--project-bcs inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                    BOSCH CAR SERVICES.
                </h2>
            </div>

            <article className="article project inuit-u-p0">

                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="http://bosch.com/" title="Programme EXTRA!" target="_blank">
                                <img src="/img/clients/BCS.jpg" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">BOSCH</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://www.programme-extra.fr/" title="Programme extra"
                                target="_blank">Programme EXTRA</a>
                            </span>
                            <span className="project__detail">Since 2015</span>
                        </p>

                        <ul className="article__share-list">
                            <li className="article__share-item">
                                <a href="#"
                                className="article__share-link article__share-link--facebook" target="blank" title="Share on Facebook"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#"
                                className="article__share-link article__share-link--twitter" target="blank" title="Share on Twitter"></a>
                            </li>
                            <li className="article__share-item">
                                <a
                                    href="#"
                                    className="article__share-link article__share-link--linkedin" target="blank" title="Share on Linkedin"></a>
                            </li>

                            <li className="article__share-item">
                                <a href="#"
                                className="article__share-link article__share-link--gplus" target="blank" title="Share on Google Plus"></a>
                            </li>
                        </ul>
                    </aside>

                    <h1 className="article__heading">BOSCH CAR SERVICES.</h1>
                    <p className="article__para">
                        Bosch Car Services est le premier réseau mondial qui assure l'entretien de TOUS les véhicules quel que soit la marque et l'âge.<br />
                        Le réseau est constitué de garages indépendants soucieux de votre sécurité et formés à toutes les technologies grâce au soutient du premier équipementier mondial.

                    </p>
                    <p className="article__para">
                        <span className="project__detail project__detail--small">Conception, Print, Display, Goodies, Digital, Spot Radio, Event & Vidéos</span>
                    </p>
                </div>

                <p className="article__belt inuit-u-mt0">
                    <img className="article__belt-image" src="/img/projects/bosch/banner-extra.jpg" alt="mobile-apps" />
                </p>

                <div className="article__body">
                    <h2 className="article__heading">Vidéo Bosch Car Services</h2>
                    <p className="article__para">

                    <span className="project__detail">Images :</span>
                    Après avoir identifié les envies et besoins du client, et mis en place un scénario de tournage, nous avons effectué le repérage des lieux et assuré le shooting.<br /><br />

                    <span className="project__detail">Rédaction :</span>
                    La rédaction du script pour voix off Fr a été produite, segmenté par parties et thèmes par nos concepteur rédacteurs & enfin traduite en Anglais.<br /><br />

                    <span className="project__detail">Son :</span>
                    Nous avons fait appel à un STUDIOS de renom pour la réalisation du Casting et de l’Enregistrement des voix off Fr et En.<br /><br />

                    <span className="project__detail">Post production, montage et calage</span>

                    </p>

                <p className="article__belt inuit-u-mt0">
                    <img className="article__belt-image" src="/img/projects/bosch/dvd-video-bcs.jpg" alt="mobile-apps" />
                </p>

                <p className="article__para">
                    <span className="project__detail project__detail--small">Vidéo Bosch Car Service - Fr</span>
                </p>

                <p className="article__belt inuit-u-m0">
                    <video className="article__video article__video--play-on-scroll" autoPlay loop muted>
                        <source src="/video/video-bcs-fr.mp4" type="video/mp4" />
                    </video>
                </p>


                <p className="article__para">
                    <span className="project__detail project__detail--small">Vidéo Bosch Car Service - En</span>
                </p>
                <p className="article__belt inuit-u-m0">
                    <video className="article__video article__video--play-on-scroll" autoPlay loop muted>
                        <source src="/video/video-bcs-en.mp4" type="video/mp4" />
                    </video>
                </p>
                </div>



                <div className="article__body">
                    <div className="article__belt inuit-u-mt0">
                        <h2 className="article__heading">Goodies Programme Extra</h2>
                        <p className="article__para">
                            Clés USB personnalisées en Silicone, avec forme de découpe eXtra
                        </p>
                        <p className="article__belt inuit-u-mt0">
                            <img className="article__belt-image" src="/img/projects/bosch/usb-key-extra.jpg" alt="mobile-apps" />
                        </p>
                            <p className="article__para">
                                Sous Mains, Stylos, Mugs, Montres Braclets, Montres Murales, ...
                            </p>
                        <p className="article__belt inuit-u-mt0">
                            <img className="article__belt-image" src="/img/projects/bosch/goodies_divers.jpg" alt="mobile-apps" />
                        </p>
                    </div>
                </div>


            </article>

            <WhoAreWe />
        </Layout>
    
  )

  openPopUp = () => {
    javascript:window.open("https://plus.google.com/share?url=https%3A%2F%2Fsubsign.co%2Fprojects%2Fcomotion", '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;
  }

  componentDidMount = () => {
    helpers.runParallaxEffect()
  }


}

export default Bosch
